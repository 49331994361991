
import React,{useEffect} from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Button, Tooltip } from "@mui/material";
import { ConnectingAirportsOutlined } from '@mui/icons-material';

let timeStamps = {}

const loadTimestamps = async () => {
  const resp = await fetch('/data/ts.json')
  const data = await resp.json()
  console.info("Data: ", data)
  return data
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const BASE_HOST = '';
const DATA_URL = BASE_HOST + "/data/"
const DOC_URL =  BASE_HOST + "/docs/"

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const LastUpdate = (props) => {
  const dt = new Date(props.ts)
  console.info("Datetime: ", dt.toLocaleString())
  return (
    <div>
      <Typography sx={{ marginBottom: '10px', fontWeight: "bold", color: "#777777" }}>Last updated {dt.toLocaleString()}</Typography>
    </div>
  )
}

function Data1994(props) {
  const file = "cps_94.zip"
  const doc_href = DOC_URL + "1994dd.pdf"
  const data_href = DATA_URL + file
  const ts = props.ts[file]

  return (
    <div>
      <div>
        <LastUpdate ts={ts} />
        <Tooltip title="">
          <Button sx={{ marginRight: "10px" }} variant="outlined" href={doc_href} target="_blank">
            Data Dictionary
          </Button>
        </Tooltip>
        <Tooltip title="">
          <Button sx={{ marginRight: "10px" }} variant="outlined" href={data_href}>
            Download Dataset (CSV)
          </Button>
        </Tooltip>
      </div>
      <p>Data taken directly from the CPS results. (From 1994 - present)</p>
    </div>
  );
}

function DataAtlanta(props) {
  const file = "CPS_harmonized_variable_longitudinally_matched_age16plus.dta.gz"
  const doc_href = DOC_URL + "Chartbook_harmonized_var_longitidunally_matched_cps.pdf"
  const code_href = DOC_URL + "atlanta_codebook.xlsx"
  const data_href = DATA_URL + file
  const ts = props.ts[file]

  return (
    <div>
      <div>
        <LastUpdate ts={ts} />
        <Tooltip title="">
          <Button sx={{ marginRight: "10px" }} variant="outlined" target="_blank" href={doc_href}>
            Charts
          </Button>
        </Tooltip>
        <Tooltip title="">
          <Button sx={{ marginRight: "10px" }} variant="outlined" href={code_href}>
            Code Book
          </Button>
        </Tooltip>
        <Tooltip title="">
          <Button sx={{ marginRight: "10px" }} variant="outlined" href={data_href}>
            Download Dataset (Stata DTA)
          </Button>
        </Tooltip>
      </div>
      <p>
        The Atlanta Fed’s Harmonized Variable and Longitudinally Matched dataset is a grab and go version of the monthly basic CPS.
        It is intended for researchers doing labor market analysis, thus it includes data for 16+ year olds only. The data are
        designed to be used right out of the box. Several Atlanta Fed products are created using this dataset, including the Wage
        Growth Tracker and Labor Force Participation Dynamics. The data contain harmonized variables that are coded consistently
        through time as well as identifiers for longitudinally matching individuals and households over time.
      </p>
      <p>
        Each variable’s start year is indicated at the end of the variable name. For example, mlr76 is coded with consistent
        values (1= employed, 2= unemployed and 3=not in labor force) from 1976 until today. The stata (.dta) file includes
        these labels already attached. For users wishing to perform time series analysis, lags of many variables are provided
        on the dataset already (for example, mlr76_tm12 is the individual’s labor force status from 12 months ago). For a visual
        of each variable over time and summary statistics see “Charts”. For information on how each variable is created see
        “Codebook”.
      </p>
    </div>
  )
}

function Data1976(props) {
  const file = "cps_76.zip"
  const doc_href = DOC_URL + "1976dd.pdf"
  const data_href = DATA_URL + file
  const ts = props.ts[file]

  return (
    <div>
      <div>
        <LastUpdate ts={ts} />
        <Tooltip title="">
          <Button sx={{ marginRight: "10px" }} variant="outlined" href={doc_href} target="_blank">
            Data Dictionary
          </Button>
        </Tooltip>
        <Button sx={{ marginRight: "10px" }} variant="outlined" href={data_href}>
          Download Dataset (CSV)
        </Button>
      </div>

      <p className="desc-header">Layouts</p>
      The monthly data and the original documentation files from this time period contain multiple layouts (adult, children, and armed forces).
      Some of the earlier CPS data contains those and other layouts. To identify which rows in a monthly file correspond to which layout,
      a combination of variables are used in the original documentation. To simplify this, the warehouse version
      contains a single variable (v9000) that can be used to identify which rows are associated with which layout.
      The primary layout, and the one likely of most interest to users, is the adult record layout. From a user
      perspective, the different layouts are not likely a major concern. But they needed to be addressed for the
      purpose of putting the data into a table.<br /><br />
      <table border="1" cellpadding="5" cellspacing="5">
        <tr>
          <th>v9000</th><th>Layout Type</th><th>First Included</th>
        </tr>
        <tr><td>1</td><td>Adult</td><td></td></tr>
        <tr><td>2</td><td>Child</td><td>January 1982</td></tr>
        <tr><td>3</td><td>Non-interview Type A</td><td>January 1982</td></tr>
        <tr><td>4</td><td>Non-interview Type B-C</td><td>January 1982</td></tr>
        <tr><td>5</td><td>Armed Forces</td><td>January 1984</td></tr>
      </table><br /><br />
    </div>
  )
}

function Data1989(props) {
  const file = "cps_89.zip"
  const doc_href = DOC_URL + "1989dd.pdf"
  const data_href = DATA_URL + file
  const ts = props.ts[file]

  return (
    <div>
      <div>
        <LastUpdate ts={ts} />
        <Tooltip title="">
          <Button sx={{ marginRight: "10px" }} variant="outlined" href={doc_href} target="_blank">
            Data Dictionary
          </Button>
        </Tooltip>
        <Button sx={{ marginRight: "10px" }} variant="outlined" href={data_href}>
          Download Dataset (CSV)
        </Button>
      </div>

      <p className="desc-header">Layouts</p>
      <p>
        The monthly data and the original documentation files from this time period contain multiple layouts (adult, children, and armed forces).
        Some of the earlier CPS data contains those and other layouts. To identify which rows in a monthly file correspond to which layout,
        a combination of variables are used in the original documentation. To simplify this, the warehouse version
        contains a single variable (v9000) that can be used to identify which rows are associated with which layout.
        The primary layout, and the one likely of most interest to users, is the adult record layout. From a user
        perspective, the different layouts are not likely a major concern. But they needed to be addressed for the
        purpose of putting the data into a table.<br /><br />
      </p>
      <table border="1" cellpadding="5" cellspacing="5">
        <tr>
          <th>v9000</th><th>Layout Type</th><th>First Included</th>
        </tr>
        <tr><td>1</td><td>Adult</td><td></td></tr>
        <tr><td>2</td><td>Child</td><td>January 1982</td></tr>
        <tr><td>3</td><td>Non-interview Type A</td><td>January 1982</td></tr>
        <tr><td>4</td><td>Non-interview Type B-C</td><td>January 1982</td></tr>
        <tr><td>5</td><td>Armed Forces</td><td>January 1984</td></tr>
      </table><br /><br />

      <p className="desc-header">Variable Name Modification</p>
      <p> The original documentation provides variable name prefixes, composed of a letter or two (H, HG, L, or A in the adult record layout;
        C in the children record layout, and M in the armed forces layout) followed by a symbol ($, -, or %)
        (for example, H$MONTH). The different symbols are used to distinguish between unedited, edited, and
        allocated data. In the warehouse version of the data, the symbols are replaced by numbers, as described
        in the table below. Additionally, in some cases the letters used in the prefixes were also replaced.
        To line up variables from the adult, children, and armed forces record layouts the letters A, C, and M. <br /><br />
      </p>

      <table border="1" cellpadding="5" cellspacing="5">
        <tr>
          <th>Original</th><th>Used</th><th>Indicates</th>
        </tr>
        <tr><td>$</td><td>1</td><td>Unedited Data</td></tr>
        <tr><td>-</td><td>2</td><td>Edited Data</td></tr>
        <tr><td>%</td><td>3</td><td>Allocated Data</td></tr>
      </table>
      <br /><br />

      <span className="desc-header">Full Set of Prefixes</span>

      <table border="1" cellpadding="5" cellspacing="5">
        <tr>
          <th colspan="3">Original</th><th>Modified</th>
        </tr>
        <tr><td colspan="3">H$</td><td>H1</td></tr>
        <tr><td colspan="3">H-</td><td>H2</td></tr>
        <tr><td colspan="3">H%</td><td>H3</td></tr>
        <tr><td colspan="3">HG-</td><td>HG2</td></tr>
        <tr><td colspan="3">L-</td><td>L2</td></tr>
        <tr><td colspan="3">L%</td><td>L33</td></tr>

        <tr><td>A$</td><td>C$</td><td>M$</td><td>v1</td></tr>
        <tr><td>A-</td><td>C-</td><td>M-</td><td>v2</td></tr>
        <tr><td>A%</td><td>C%</td><td>M%</td><td>v3</td></tr>
      </table>
      <br /><br />

    </div>

  )
}

function DataHarmonized(props) {
  const file = "cps_link.zip"
  const doc_href = DOC_URL + "harmonizeddd.pdf"
  const data_href = DATA_URL + file
  const ts = props.ts[file]

  return (
    <div>
      <div>
        <LastUpdate ts={ts} />
        <Tooltip title="">
          <Button sx={{ marginRight: "10px" }} variant="outlined" href={doc_href} target="_blank">
            Data Dictionary
          </Button>
        </Tooltip>
        <Button sx={{ marginRight: "10px" }} variant="outlined" href={data_href}>
          Download Dataset (CSV)
        </Button>
      </div>
      <p>
        The CPS Linked Variable data are designed to facilitate the study of the
        CPS in a consistent manner over time.  This dataset "links" variables in
        the 1976, 1989, and 1994 versions of the CPS where possible, allowing
        researchers to trace major survey concepts over time. Linked variables in
        this dataset can be linked either between the 1976 and the 1989 versions
        of the CPS, the 1989 and the 1994 versions of the CPS, or across all three
        versions.
      </p>
    </div>
  )
}

function DataLong(props) {

  const file = "Longitudinally_Matched_CPS_Data_Mustre-del-Rio_and_Tuzemen_Approach.zip"
  const doc_href = DOC_URL + "longdd.pdf"
  const data_href = DATA_URL + file
  const ts = props.ts[file]

  return (
    <div>
      <div>
        <LastUpdate ts={ts} />
        <Tooltip title="">
          <Button sx={{ marginRight: "10px" }} variant="outlined" href={doc_href} target="_blank">
            Data Dictionary
          </Button>
        </Tooltip>
        <Tooltip title="">
          <Button sx={{ marginRight: "10px" }} variant="outlined" href={data_href}>
            Download Dataset (CSV)
          </Button>
        </Tooltip>
        <p>
          The CPS Linked Variable data are designed to facilitate the study of the CPS in a consistent manner over time.
          This dataset "links" variables in the 1976, 1989, and 1994 versions of the CPS where possible, allowing researchers to trace
          major survey concepts over time. Linked variables in this dataset can be linked either between the 1976 and the 1989 versions
          of the CPS, the 1989 and the 1994 versions of the CPS, or across all three versions.
        </p>
      </div>
    </div>
  );
}

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');
  const [timestamps, setTimestamps] = React.useState({})

  useEffect(() => {
    async function getTs() {
      console.info("Loading timestamps")
      const ts = await loadTimestamps()
      setTimestamps(ts)
    }
    getTs()
  },[])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="App-datasets">
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>As Published (1994 - Present)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Data1994 ts={timestamps} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>As Published (1989 - 1993)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Data1989 ts={timestamps}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>As Published (1976-1988)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Data1976 ts={timestamps}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>Harmonized Variable (1976 - Present)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataHarmonized ts={timestamps}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography>Longitudinally Matched [Mustre-del-Rio & Tuzemen Approach] (1994 - Present)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataLong ts={timestamps}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography >Harmonized Variable and Longitudinally Matched [Atlanta Federal Reserve] (1976-Present) </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataAtlanta ts={timestamps}/>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
